<template>
  <a-modal
    :visible="visible"
    :title="`确认向${user.name}发 offer 吗？`"
    :footer="null"
    @cancel="cancel"
    :keyboard="false"
    :maskClosable="false"
    width="580px"
  >
    <a-form
      :form="form"
      :colon="false"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item label="选择模版">
        <a-radio-group
          v-decorator="[
            'tem',
            { rules: [{ required: true, message: '请选择！' }] },
          ]"
        >
          <a-radio
            v-for="item in [
              {
                name: '默认模版',
                value: 'default',
              },
              {
                name: '自定义模版',
                value: 'custom',
              },
            ]"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-radio
          >
        </a-radio-group>
      </a-form-item>

      <template v-if="form.getFieldValue('tem') === 'default'">
        <a-form-item label="报到时间">
          <a-date-picker
            placeholder=""
            format="YYYY-MM-DD HH:mm"
            :showTime="{
              format: 'HH:mm',
            }"
            v-decorator="[
              'inductionTime',
              {
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="报到地点">
          <a-input
            v-decorator="[
              'reportAddress',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
      </template>

      <template v-if="form.getFieldValue('tem') === 'custom'">
        <a-form-item>
          <span slot="label" class="required">自定义附件</span>

          <Uploader v-if="visible" @change="onFileChange" :showDelete="true" />
        </a-form-item>
      </template>

      <template v-if="form.getFieldValue('tem')">
        <a-form-item label="更多附件">
          <FileUpload @uploaded="onFileUpload">
            <a-button>上传</a-button>
          </FileUpload>
          <Padding />
          <a-table
            bordered
            size="small"
            :data-source="fileList"
            :pagination="false"
          >
            <a-table-column title="名称" align="center">
              <template slot-scope="text">
                <a :href="text.completePath" target="_blank">{{ text.name }}</a>
              </template>
            </a-table-column>

            <a-table-column title="操作" align="center">
              <template slot-scope="text, row, index">
                <a-space>
                  <a class="danger" @click.prevent="deleteFile(index)">删除</a>
                </a-space>
              </template>
            </a-table-column>
          </a-table>
        </a-form-item>
      </template>

      <template v-if="form.getFieldValue('tem') === 'default'">
        <a-form-item label="预览效果">
          <a href="#" @click.prevent="preview">查看</a>
        </a-form-item>
      </template>

      <div class="right" v-if="form.getFieldValue('tem')">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="loading">
            发送
          </a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { hire, previewOffer } from "@/api/recruit";
import FileUpload from "@/components/file-upload";
import Uploader from "./uploader.vue";
export default {
  components: {
    FileUpload,
    Uploader,
  },

  props: {
    visible: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      fileList: [],
      attachmentOffer: null, // 附件 id
      loading: false,
    };
  },

  methods: {
    onFileUpload(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile(index) {
      this.fileList.splice(index, 1);
    },

    onFileChange(fileId) {
      this.attachmentOffer = fileId;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          let task;
          if (values.tem === "default") {
            task = hire({
              inductionTime: values.inductionTime.format("YYYY-MM-DD HH:mm"),
              reportAddress: values.reportAddress,
              id: this.user.id,
              attachmentOfferOthers:
                this.fileList.length > 0
                  ? this.fileList.map((item) => item.id).join(",")
                  : undefined,
            });
          } else {
            if (!this.attachmentOffer) {
              this.$message.error("请上传自定义附件");
              return;
            }
            task = hire({
              id: this.user.id,
              attachmentOffer: this.attachmentOffer
                ? this.attachmentOffer
                : undefined,
              attachmentOfferOthers:
                this.fileList.length > 0
                  ? this.fileList.map((item) => item.id).join(",")
                  : undefined,
            });
          }

          this.loading = true;

          task
            .then(() => {
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    preview() {
      this.form.validateFields((err, values) => {
        if (!err) {
          previewOffer({
            inductionTime: values.inductionTime.format("YYYY-MM-DD HH:mm"),
            reportAddress: values.reportAddress,
            id: this.user.id,
          }).then((blob) => {
            const newBlob = new Blob([blob], {
              type: "application/pdf",
            });
            window.open(window.URL.createObjectURL(newBlob), "_blank");
          });
        }
      });
    },

    cancel() {
      this.form.resetFields();
      this.fileList = [];
      this.attachmentOffer = null;
      this.$emit("setVisible", false);
    },
  },
};
</script>

<style lang="less" scoped>
</style>