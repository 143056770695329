var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"面试邀请","footer":null},on:{"cancel":_vm.cancel}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.user.name ? `确认向${_vm.user.name}发送面试邀请吗？` : "确定批量发送面试邀请吗？")+" ")]),(_vm.userNames.length > 0)?_c('div',{staticStyle:{"margin-bottom":"15px","margin-left":"60px"}},[_vm._v(" 受邀人:"+_vm._s(_vm.userNames.join(','))+" ")]):_vm._e(),_c('a-form',{attrs:{"form":_vm.form,"colon":false,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"面试阶段"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phase',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'phase',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}]},_vm._l((_vm.phaseList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"面试方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'method',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'method',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}]},_vm._l((_vm.interviewMethodList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.form.getFieldValue('method') === 'offline')?_c('a-form-item',{attrs:{"label":"面试地点"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roomId',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'roomId',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}]},_vm._l((_vm.meetingRoomList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),(_vm.form.getFieldValue('method') === 'online')?_c('a-form-item',{attrs:{"label":"腾讯会议号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'onlineRoomId',
          { rules: [{ required: true, message: '请输入！' }] },
        ]),expression:"[\n          'onlineRoomId',\n          { rules: [{ required: true, message: '请输入！' }] },\n        ]"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"面试时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'interviewDate',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'interviewDate',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","format":"YYYY-MM-DD HH:mm","showTime":{
          format: 'HH:mm',
        }}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks']),expression:"['remarks']"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" 发送 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }