var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":`确认向${_vm.user.name}发 offer 吗？`,"footer":null,"keyboard":false,"maskClosable":false,"width":"580px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"选择模版"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tem',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'tem',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}]},_vm._l(([
            {
              name: '默认模版',
              value: 'default',
            },
            {
              name: '自定义模版',
              value: 'custom',
            },
          ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.form.getFieldValue('tem') === 'default')?[_c('a-form-item',{attrs:{"label":"报到时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'inductionTime',
            {
              rules: [{ required: true, message: '请选择！' }],
            },
          ]),expression:"[\n            'inductionTime',\n            {\n              rules: [{ required: true, message: '请选择！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","format":"YYYY-MM-DD HH:mm","showTime":{
            format: 'HH:mm',
          }}})],1),_c('a-form-item',{attrs:{"label":"报到地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'reportAddress',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'reportAddress',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1)]:_vm._e(),(_vm.form.getFieldValue('tem') === 'custom')?[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("自定义附件")]),(_vm.visible)?_c('Uploader',{attrs:{"showDelete":true},on:{"change":_vm.onFileChange}}):_vm._e()],1)]:_vm._e(),(_vm.form.getFieldValue('tem'))?[_c('a-form-item',{attrs:{"label":"更多附件"}},[_c('FileUpload',{on:{"uploaded":_vm.onFileUpload}},[_c('a-button',[_vm._v("上传")])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","size":"small","data-source":_vm.fileList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":text.completePath,"target":"_blank"}},[_vm._v(_vm._s(text.name))])]}}],null,false,331368376)}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_c('a-space',[_c('a',{staticClass:"danger",on:{"click":function($event){$event.preventDefault();return _vm.deleteFile(index)}}},[_vm._v("删除")])])]}}],null,false,2699217304)})],1)],1)]:_vm._e(),(_vm.form.getFieldValue('tem') === 'default')?[_c('a-form-item',{attrs:{"label":"预览效果"}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.preview.apply(null, arguments)}}},[_vm._v("查看")])])]:_vm._e(),(_vm.form.getFieldValue('tem'))?_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" 发送 ")])],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }