import request from '../request'

// 会议室接口
export function fetchList(params) {
    return request({
        url: '/office-service/meetRoom/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/office-service/meetRoom/add',
        method: 'post',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: `/office-service/meetRoom/query/${params.id}`,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/meetRoom/update',
        method: 'post',
        data
    })
}

export function remove(data) {
    return request({
        url: '/office-service/meetRoom/del',
        method: 'post',
        data
    })
}

// 申请预约会议
export function addMeeting(data) {
    return request({
        url: '/office-service/meetBookInfo/add',
        method: 'post',
        data
    })
}
// 获取预约记录
export function fetchMeetingList(params) {
    return request({
        url: '/office-service/meetBookInfo/list',
        params
    })
}
// 通过或驳回预约
export function adoptMeeting(data) {
    return request({
        url: '/office-service/meetBookInfo/audit',
        method: 'post',
        data,
    })
}
// 获取会议预约记录详情
export function fetchMeetingDetail(params) {
    return request({
        url: `/office-service/meetBookInfo/query/${params.id}`,
    })
}

// 重新申请预约
export function editMeeting(data) {
    return request({
        url: '/office-service/meetBookInfo/update',
        method: 'post',
        data
    })
}
// 取消预约
export function cancelMeeting(data) {
    return request({
        url: '/office-service/meetBookInfo/cancel',
        method: 'post',
        data,
    })
}

// 根据会议室 code 和当前时间查该会议室预约记录（大屏系统专属）
export function fetchListByCode(params) {
    return request({
        url: '/office-service/meetBookInfo/getListByCodeDate',
        params,
    })
}