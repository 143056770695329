<template>
  <div>
    <a-button :loading="loading" @click="onClick" type="primary">
      <input
        ref="upload"
        style="display: none"
        type="file"
        @change="onChange"
      />

      {{
        file.completePath || uploadedFile.completePath ? "重新上传" : "立即上传"
      }}
    </a-button>
    <template v-if="file.completePath">
      <a :href="file.completePath" target="_blank" style="padding-left: 8px"
        >预览</a
      >
      <a
        href="#"
        class="danger"
        style="padding-left: 8px"
        @click="remove"
        v-if="showDelete"
        >删除</a
      >
    </template>
    <template v-else-if="uploadedFile.completePath">
      <a
        :href="uploadedFile.completePath"
        target="_blank"
        style="padding-left: 8px"
        >预览</a
      >
      <a
        href="#"
        class="danger"
        style="padding-left: 8px"
        @click="remove"
        v-if="showDelete"
        >删除</a
      >
    </template>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      uploadedFile: {},
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            this.$emit("change", res[0].id);
            this.uploadedFile = res[0];
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    remove() {
      this.uploadedFile = {};
      this.$emit("change", null);
    },
  },
};
</script>